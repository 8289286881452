// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sections: [
    { label1: 'geistige_schoepfer' },
    { label1: 'andere_namen_titel_synonyme' },
    { label1: 'beschreibende_angaben' },
    { label1: 'gnd_systematik' },
    { label1: 'ddc_notationen' },
  ],
  content: {
    geistige_schoepfer: [
      { relations: 'aut1|kom1|kue1' },
      { relations: 'auta|koma|kuen' },
      { relations: 'arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd' },
    ],
    andere_namen_titel_synonyme: [
      { label2: 'andere_namen' },
      { label2: 'andere_titel' },
      { label2: 'synonyme_infopanel' },
      { label2: 'aus_anderen_vokabularen' },
    ],
    beschreibende_angaben: [
      { relations: 'datb|datf|datj|datl|dats|datv' },
      { label2: 'beruf_infopanel', relations: 'berc' },
      { label2: 'beruf_infopanel', relations: 'beru' },
      { label2: 'geografischer_bezug_infopanel' },
      { label2: 'sprachencodes' },
    ],
    ddc_notationen: [
      { label2: 'ddc_notationen_infopanel', subfield: 'd', sort: 'desc' },
      { label2: 'ddc_notationen_infopanel', subfield: '-d' },
    ],
  },
  fields: {
    '022@': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'andere_titel' },
      render: [
        { type: 'text', subfield: 'a|t|g|m|n|p|o|s|x|f|r|l|h' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '022P': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'aus_anderen_vokabularen' },
      render: [
        { type: 'text', subfield: 'a|t|g|m|n|p|o|s|x|f|r|l|h|>a|>t|>g|>m|>n|>p|>o|>s|>x|>f|>r|>l|>h' },
        {
          type: 'additional',
          subfield: 'L|2|>2|4|-4',
          tooltips: [
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: '2|>2', ns: 'identifierSources' },
            { subfield: '4', ns: 'relationCodes' },
          ],
        },
      ],
      collapseMultipleEntries: true,
    },
    '028@': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'andere_namen' },
      render: [
        { type: 'text', subfield: 'd' },
        { type: 'text', subfield: 'c' },
        { type: 'text', subfield: 'a|P' },
        { type: 'text', subfield: 'n|l|g|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '028P': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'aus_anderen_vokabularen' },
      render: [
        { type: 'text', subfield: 'P|a|d|c|n|l|g|x|>P|>a|>d|>c|>n|>l|>g|>x' },
        {
          type: 'additional',
          subfield: 'L|2|>2|4|-4',
          tooltips: [
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: '2|>2', ns: 'identifierSources' },
            { subfield: '4', ns: 'relationCodes' },
          ],
        },
      ],
      collapseMultipleEntries: true,
    },
    '028R': {
      label1: 'geistige_schoepfer',
      content: { ns: 'label2', label2: 'geistige_schoepfer_infopanel', relations: 'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd' },
      render: [
        { type: 'link', id: '>9', text: '>name|P|a|d|c|n|l|g|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '029@': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'andere_namen' },
      render: [
        { type: 'text', subfield: 'a|n|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '029P': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'aus_anderen_vokabularen' },
      render: [
        { type: 'text', subfield: 'a|n|g|b|x|>a|>n|>g|>b|>x' },
        {
          type: 'additional',
          subfield: 'L|2|>2|4|-4',
          tooltips: [
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: '2|>2', ns: 'identifierSources' },
            { subfield: '4', ns: 'relationCodes' },
          ],
        },
      ],
      collapseMultipleEntries: true,
    },
    '029R': {
      label1: 'geistige_schoepfer',
      content: { ns: 'label2', label2: 'geistige_schoepfer_infopanel', relations: 'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd' },
      render: [
        { type: 'link', id: '>9', text: '>name|a|n|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '030@': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'andere_namen' },
      render: [
        { type: 'text', subfield: 'a|n|d|c|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '030P': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'aus_anderen_vokabularen' },
      render: [
        { type: 'text', subfield: 'a|n|d|c|g|b|x|>a|>n|>d|>c|>g|>b|>x' },
        {
          type: 'additional',
          subfield: 'L|2|>2|4|-4',
          tooltips: [
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: '2|>2', ns: 'identifierSources' },
            { subfield: '4', ns: 'relationCodes' },
          ],
        },
      ],
      collapseMultipleEntries: true,
    },
    '030R': {
      label1: 'geistige_schoepfer',
      content: { ns: 'label2', label2: 'geistige_schoepfer_infopanel', relations: 'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd' },
      render: [
        { type: 'link', id: '>9', text: '>name|a|n|d|c|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '037G': {
      label1: 'ddc_notationen',
      content: { ns: 'label2', label2: 'ddc_notationen_infopanel' },
      render: [
        { type: 'text', subfield: 'c' },
        {
          type: 'additional',
          subfield: 'd',
          tooltips: [{ subfield: 'd', ns: 'ddcNotations' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '041@': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'synonyme_infopanel' },
      render: [{ type: 'text', subfield: 'a|g|x' }],
      collapseMultipleEntries: true,
    },
    '041P': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'aus_anderen_vokabularen' },
      render: [
        { type: 'text', subfield: 'a|g|x|>a|>g|>x' },
        {
          type: 'additional',
          subfield: 'L|2|>2|4|-4',
          tooltips: [
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: '2|>2', ns: 'identifierSources' },
            { subfield: '4', ns: 'relationCodes' },
          ],
        },
      ],
      collapseMultipleEntries: true,
    },
    '041R': {
      label1: 'beschreibende_angaben',
      content: { ns: 'label2', label2: 'beruf_infopanel' },
      render: [
        { type: 'link', id: '>9', text: '>name|a|g|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '042A': {
      label1: 'gnd_systematik',
      content: { ns: 'label2', label2: 'gnd_systematik' },
      render: [
        {
          type: 'text',
          subfield: 'a',
          tooltips: [{ subfield: 'a', ns: 'gndSystematic' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '042B': {
      label1: 'beschreibende_angaben',
      content: { ns: 'label2', label2: 'laender' },
      render: [
        { type: 'text', subfield: 'a' },
        { type: 'additional', subfield: 'a', ns: 'countryCodes' },
      ],
      collapseMultipleEntries: true,
    },
    '042C': {
      label1: 'beschreibende_angaben',
      content: { ns: 'label2', label2: 'sprachencodes' },
      render: [
        { type: 'text', subfield: 'a' },
        { type: 'additional', subfield: 'a', ns: 'languageCodes' },
      ],
      collapseMultipleEntries: true,
    },
    '060R': {
      label1: 'beschreibende_angaben',
      content: { ns: 'relationCodes', relations: 'datb|datf|datj|dats|datv' },
      render: [
        { type: 'text', subfield: 'M|c|d' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
    },
    '065@': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'andere_namen' },
      render: [
        { type: 'text', subfield: 'a|g|z|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '065P': {
      label1: 'andere_namen_titel_synonyme',
      content: { ns: 'label2', label2: 'aus_anderen_vokabularen' },
      render: [
        { type: 'text', subfield: 'a|g|z|x|>a|>g|>z|>x' },
        {
          type: 'additional',
          subfield: 'L|2|>2|4|-4',
          tooltips: [
            { subfield: 'L', ns: 'languageCodes' },
            { subfield: '2|>2', ns: 'identifierSources' },
            { subfield: '4', ns: 'relationCodes' },
          ],
        },
      ],
      collapseMultipleEntries: true,
    },
    '065R': {
      label1: 'geistige_schoepfer',
      content: { ns: 'label2', label2: 'geistige_schoepfer_infopanel', relations: 'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd' },
      render: [
        { type: 'link', id: '>9', text: '>name|a|g|z|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
  },
  label3: [],
};
