import MapConfig from '../../../../conf/map';
import TimelineConfig from '../../../../conf/timeline';
import { TimelineUtils } from '../../../model/timeline/TimelineUtils';
import { DEFAULT_PAGE_SIZE } from '../../Config';
import { SearchView, SearchViews } from '../../GeneralUI';

export function prepareSearchQuery(searchQuery: string, view: SearchView) {
  let updatedSearchQuery: string = addRowsIfNotSet(searchQuery);
  updatedSearchQuery = addMapTimelineFacetsIfMapOrTimelineView(
    updatedSearchQuery,
    view,
  );
  return updatedSearchQuery;
}

function addRowsIfNotSet(searchQuery: string) {
  if (searchQuery.includes('rows=')) {
    return searchQuery;
  }
  return searchQuery.length === 0
    ? `?rows=${DEFAULT_PAGE_SIZE}`
    : `${searchQuery}&rows=${DEFAULT_PAGE_SIZE}`;
}

function addMapTimelineFacetsIfMapOrTimelineView(
  searchQuery: string,
  view: SearchView,
): string {
  if (view && view === SearchViews.list) {
    return searchQuery;
  }
  const facetIds: string[] = [];
  if (view && view === SearchViews.map) {
    facetIds.push(MapConfig.geoAllFacet);
    facetIds.push(MapConfig.noPositionFacet);
  }
  if (view && view === SearchViews.timeline) {
    facetIds.push(TimelineConfig.noRangeFacet);
    TimelineConfig.relations.forEach((relationCode: string) =>
      facetIds.push(TimelineUtils.getTimelineFacetIdHasRelation(relationCode)),
    );
  }

  const facets: string[] = facetIds.map(
    (facetId: string) => `fct.${facetId}.enabled=true`,
  );

  const isMobileDevice: boolean = /Android|iPhone/i.test(navigator.userAgent);
  if (isMobileDevice) {
    facets.push(`fct.${TimelineConfig.facets.relation}.mobile=true`);
  }

  return [searchQuery, ...facets].join('&');
}
