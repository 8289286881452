import { call, put, select } from 'redux-saga/effects';
import { Context, SearchContext } from '../../../model/search/context';
import {
  loadSearchConfigurationIfNotLoaded,
  selectSearchConfigurationForContext,
} from '../../SearchConfiguration';
import {
  SearchConfigurationResponse,
  SearchResponse,
} from '../../../model/api/responses';
import * as api from '../../../api';
import { SearchRequest } from '../../../model/search/request';
import { SearchRequestFactory } from '../../../model/search/factories';
import { selectRouterHash } from '../../Router/selectors';
import { selectCurrentSearchContext } from '../selectors';
import { actions } from '../slice';
import { goToSearchPage } from './goToSearchPage';
import { handleSearchError } from './handleSearchError';
import { prepareSearchQuery } from './doSearch';

export function* doDefaultSearch(action) {
  const searchQuery: string = prepareSearchQuery(
    action.payload.searchQuery,
    action.payload.view,
  );
  yield call(doSearchQuery, action.payload.lang, searchQuery);
}

export function* doSearchQuery(lang: string, searchQuery: string) {
  try {
    yield call(loadSearchConfigurationIfNotLoaded, Context.default);

    const jsonResponse: SearchResponse = yield call(
      api.search,
      searchQuery,
      Context.default,
    );

    yield put(
      actions.searchSuccess({ jsonResponse, context: Context.default }),
    );

    const { queryString, hash } = yield call(
      resolveQueryStringAndHash,
      jsonResponse,
    );
    yield call(goToSearchPage, lang, queryString, hash);
  } catch (error: any) {
    yield call(handleSearchError, error, Context.default);
  }
}

function* resolveQueryStringAndHash(jsonResponse: SearchResponse) {
  const searchContext: SearchContext = yield select(selectCurrentSearchContext);
  const searchConfiguration: SearchConfigurationResponse = yield select(
    selectSearchConfigurationForContext(searchContext),
  );
  const searchRequest: SearchRequest =
    SearchRequestFactory.createFromResponseRequest(
      jsonResponse.request,
      searchConfiguration,
    );

  const queryString: string = searchRequest.buildUrlQueryString();
  let urlHash: string = yield select(selectRouterHash);

  return { queryString, hash: urlHash };
}
