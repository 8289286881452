import {
  SearchRequestFacet,
  FacetResponseItem,
  ConfigurationResponseTermsFacet,
} from '../../api/responses';
import { Bucket } from '../buckets';
import { Facet } from './Facet';
import { FACET_MISSING_VALUE } from './FacetType';

export class TermsFacet extends Facet {
  protected _numBuckets: number = 0;
  protected _showEmptyBuckets: boolean = false;
  protected _sort: string = 'index desc';
  protected _missing: Bucket | null = null;

  public init(
    facetResponseJson: FacetResponseItem,
    facetRequestJson: SearchRequestFacet,
    config: ConfigurationResponseTermsFacet,
  ): void {
    this._numBuckets = this.buckets.length;
    this._showEmptyBuckets = config.showEmptyBuckets;
    this._sort = config.sort;

    if (config.missing) {
      const bucketMissingIndex: number = this.buckets.findIndex(
        (b: Bucket) => b.value === FACET_MISSING_VALUE,
      );

      if (bucketMissingIndex > -1) {
        this._missing = this.buckets[bucketMissingIndex];
        const bucketsWithoutMissing = [...this.buckets];
        bucketsWithoutMissing.splice(bucketMissingIndex, 1);
        this._buckets = bucketsWithoutMissing;
      }
    }
  }

  get showEmptyBuckets(): boolean {
    return this._showEmptyBuckets;
  }

  get sort(): string {
    return this._sort;
  }

  get missing(): Bucket | null {
    return this._missing;
  }
  get numBuckets(): number {
    return this._numBuckets;
  }
  public isEmpty(): boolean {
    return this.buckets.length === 0;
  }

  get bucketsWithMissing(): Bucket[] {
    if (this._missing) {
      return [this._missing, ...this._buckets];
    }
    return this._buckets;
  }
}
