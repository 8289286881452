import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../locales/i18n';
import { SearchViews } from '../features/GeneralUI';

export class RouteUtils {
  public static localizeRoute(currentLanguage: string, url: string) {
    const langPath =
      currentLanguage === DEFAULT_LANGUAGE ? '' : `/${currentLanguage}`;
    return `${langPath}${url}`;
  }

  public static isEntityPage(pathname: string): boolean {
    const regex: RegExp = getPathStartsWithRegex('gnd');
    return regex.test(pathname);
  }

  public static isSearchPage(pathname: string): boolean {
    const regex: RegExp = getPathStartsWithRegex('search');
    return regex.test(pathname);
  }

  public static isSearchListPage(pathname: string): boolean {
    const isSearchPage: boolean = RouteUtils.isSearchPage(pathname);
    return (
      isSearchPage &&
      !(
        pathname.includes(SearchViews.map) ||
        pathname.includes(SearchViews.timeline)
      )
    );
  }

  public static isEntityOrSearchPage(pathname: string): boolean {
    return (
      RouteUtils.isEntityPage(pathname) || RouteUtils.isSearchPage(pathname)
    );
  }
}

export function getPathStartsWithRegex(pathname: string): RegExp {
  const pathnameWithoutLeadingSlash = pathname.startsWith('/')
    ? pathname.substring(1)
    : pathname;
  const languages = SUPPORTED_LANGUAGES.join('|');
  const pattern = `^(/(${languages}))?/${pathnameWithoutLeadingSlash}`;
  return new RegExp(pattern);
}
