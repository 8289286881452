import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { initialState } from './slice';
import { EntityViews, StatefulUI } from './types';

// First makeSelect the relevant part from the state
const selectGeneralUI = (state: RootState) => state.generalUI || initialState;

export const selectShowFactsheetLabels = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.factsheetLabelsON];
  },
);

export const selectShowFactsheetDevData = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.factsheetDevDataON];
  },
);

export const selectShowInfopanel = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.graphInfopanelON];
  },
);

export const selectLoadingInfopanel = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.loadingInfopanel];
  },
);

export const selectEntityAndSearchViews = createSelector(
  [selectGeneralUI],
  substate => substate.view,
);

export const selectEntityView = createSelector(
  [selectGeneralUI],
  substate => substate.view.entity,
);

export const selectSearchView = createSelector(
  [selectGeneralUI],
  substate => substate.view.search,
);

export const selectHierarchyPolyTooltip = createSelector(
  [selectGeneralUI],
  substate => substate[StatefulUI.hierarchyPolyTooltip],
);

export const selectIsPolyNodeHovered = (id: string) =>
  createSelector(
    [selectGeneralUI],
    substate => substate[StatefulUI.hierarchyPolyTooltip].id === id,
  );

export const selectIsFactsheetView = createSelector(
  [selectEntityView],
  view => view === EntityViews.factsheet,
);

export const selectShowFiltersMobileOverlay = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.filtersPanelOn];
  },
);

export const selectVisibleMapRelations = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.mapRelations];
  },
);

export const selectIsTimelineDropdownOpen = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.timelineDropdownOpen];
  },
);
export const selectIsTimelineAccordionOpen = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.timelineAccordionOpen];
  },
);
export const selectHasTimelineRangeError = createSelector(
  [selectGeneralUI],
  substate => {
    return substate[StatefulUI.timelineRangeErrorON];
  },
);

export const selectPageTitle = createSelector([selectGeneralUI], substate => {
  return substate.pageTitle;
});

export const selectActiveOverlay = createSelector(
  [selectGeneralUI],
  substate => substate[StatefulUI.activeOverlay],
);
