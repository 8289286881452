import { call, put, select } from 'redux-saga/effects';
import * as api from '../../../api';
import { ErrorsEnum } from '../../../model/api/request';
import { SearchConfigurationResponse } from '../../../model/api/responses';
import { SearchConfigurationResponseProcessor } from '../../../model/search/configuration/SearchConfigurationResponseProcessor';
import { SearchContext } from '../../../model/search/context';
import {
  selectIsSearchConfigurationLoaded,
  selectSearchConfigurationForContext,
} from '../selectors';
import { actions } from '../slice';

export function* loadSearchConfigurationIfNotLoaded(
  searchContext: SearchContext,
) {
  try {
    const isConfigurationLoaded: boolean = yield select(
      selectIsSearchConfigurationLoaded(searchContext),
    );
    if (!isConfigurationLoaded) {
      yield call(loadSearchConfiguration, searchContext);
    }
    return yield select(selectSearchConfigurationForContext(searchContext));
  } catch (e: any) {
    throw new Error(ErrorsEnum.NoSearchConfigurations);
  }
}

function* loadSearchConfiguration(searchContext: SearchContext) {
  const response: SearchConfigurationResponse = yield call(
    api.fetchSearchConfiguration,
    searchContext,
  );
  const processedResponse: SearchConfigurationResponse =
    SearchConfigurationResponseProcessor.process(response);

  yield put(
    actions.searchConfigurationLoadedSuccess({
      response: processedResponse,
      searchContext,
    }),
  );
}
