import {
  emptySearchConfigurationJson,
  SearchConfigurationResponse,
} from '../../api/responses';
import { Context } from '../context';

export interface SearchConfigurations {
  [Context.default]: SearchConfigurationResponse;
  [Context.entityMap]: SearchConfigurationResponse;
  [Context.entityHasMap]: SearchConfigurationResponse;
}

export const initialSearchConfigurations: SearchConfigurations = {
  [Context.default]: emptySearchConfigurationJson,
  [Context.entityMap]: emptySearchConfigurationJson,
  [Context.entityHasMap]: emptySearchConfigurationJson,
};

export interface SearchContextConfigurationLoaded {
  [Context.default]: boolean;
  [Context.entityMap]: boolean;
  [Context.entityHasMap]: boolean;
}

export const initialSearchConfigurationLoaded: SearchContextConfigurationLoaded =
  {
    [Context.default]: false,
    [Context.entityMap]: false,
    [Context.entityHasMap]: false,
  };
