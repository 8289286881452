import { call } from 'redux-saga/effects';
import { request } from '../../utils/request';
import { SearchConfigurationResponse } from '../model/api/responses';
import { SearchContext } from '../model/search/context';
import { getConfigRequestUrl } from './getRequestUrl';

export function* fetchSearchConfiguration(searchContext: SearchContext) {
  const url = yield call(getConfigRequestUrl, searchContext);
  const response: SearchConfigurationResponse = yield call(request, url);
  return response;
}
