import { call, put } from 'redux-saga/effects';
import * as api from '../../../api';
import { SearchResponse } from '../../../model/api/responses';
import { Context, SearchContext } from '../../../model/search/context';
import { loadSearchConfigurationIfNotLoaded } from '../../SearchConfiguration';
import { actions } from '../slice';
import { handleSearchError } from './handleSearchError';

export function* searchEntityHasMap(action) {
  const context: SearchContext = Context.entityHasMap;
  try {
    yield call(loadSearchConfigurationIfNotLoaded, context);

    const searchQuery: string = `term=id:${action.payload.id}`;

    const jsonResponse: SearchResponse = yield call(
      api.search,
      searchQuery,
      context,
    );
    yield put(actions.searchSuccess({ jsonResponse, context }));
  } catch (error: any) {
    yield call(handleSearchError, error, context);
  }
}
