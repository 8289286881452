import { RequestErrorPayload } from './types';

export interface RequestState {
  loading: boolean;
  error: RequestErrorPayload | null;
}

type valueof<T> = T[keyof T];
export enum RequestStatus {
  Unprocessed = 'unprocessed',
  InProgress = 'in_progress',
  Complete = 'complete',
  Error = 'error',
}
export type RequestStatusType = valueof<RequestStatus>;
