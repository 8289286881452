// eslint-disable-next-line import/no-anonymous-default-export
export default {
  geoAllFacet: 'geo_all',
  noPositionFacet: 'geo_none',
  hasGeoDataFacet: 'has_geo_data',
  markers: {
    // clockwise order of relations, starting from the 12hours position
    relations: [
      'orta',
      'ortb',
      'ortc',
      'ortf',
      'ortg',
      'orth',
      'orts',
      'ortv',
      'ortw',
      'ortx',
    ],
    colors: {
      orta: '#4BC6ED',
      ortb: '#B9E048',
      ortc: '#CB0000',
      ortf: '#16C792',
      ortg: '#4477FA',
      orth: '#EC6F15',
      orts: '#A9E5FF',
      ortv: '#EF5B99',
      ortw: '#E9C714',
      ortx: '#7860DD',
      slice_off: '#E2E2E2',
    },
  },
};
