import FacetConfig from '../../../../conf/facets';
import {
  ConfigurationResponseFacet,
  SearchRequestFacet,
  FacetResponseItem,
  ConfigurationResponseFilterMap,
  ConfigurationResponseTermsFacet,
} from '../../api/responses';
import { Bucket, BucketFactory } from '../buckets';
import { FacetType, FacetTypes } from './FacetType';

export abstract class Facet {
  protected _configuration: ConfigurationResponseFacet;
  protected _buckets: Bucket[];
  protected _filtersConfiguration: ConfigurationResponseFilterMap;

  public constructor(
    configuration: ConfigurationResponseFacet,
    buckets: Bucket[],
    filterConfigurations: ConfigurationResponseFilterMap,
  ) {
    this._configuration = configuration;
    this._buckets = buckets;
    this._filtersConfiguration = filterConfigurations;
  }

  public abstract isEmpty(): boolean;

  public static create<F extends Facet>(
    FacetInstance: new (
      configuration: ConfigurationResponseFacet,
      buckets: Bucket[],
      filterConfigurations: ConfigurationResponseFilterMap,
    ) => F,
    facetResponseJson: FacetResponseItem,
    facetRequestJson: SearchRequestFacet,
    configuration: ConfigurationResponseFacet,
    filterConfigurations: ConfigurationResponseFilterMap,
  ): F {
    const buckets: Bucket[] = [];
    for (const b of facetResponseJson.buckets as any) {
      const bucket: Bucket = BucketFactory.create(b);
      buckets.push(bucket);
    }
    const facet: F = new FacetInstance(
      configuration,
      buckets,
      filterConfigurations,
    );
    facet.init(facetResponseJson, facetRequestJson, configuration);
    return facet;
  }

  public abstract init(
    facetResponseJson: FacetResponseItem,
    facetRequestJson: SearchRequestFacet,
    configuration: ConfigurationResponseFacet,
  ): void;

  get configuration(): ConfigurationResponseFacet {
    return this._configuration;
  }

  get title(): string {
    return this._configuration.title;
  }

  get id(): string {
    return this._configuration.id;
  }

  get buckets(): Bucket[] {
    return this._buckets;
  }

  get visibleBuckets(): Bucket[] {
    return this._buckets;
  }

  get type(): FacetType {
    return this._configuration.type;
  }

  get filterId(): string {
    return this._configuration.filterId;
  }

  get filterType(): string {
    return this._filtersConfiguration[this._configuration.filterId].type;
  }

  get hasMissing(): boolean {
    if (this._configuration.type !== FacetTypes.terms) {
      return false;
    }
    const config = this._configuration as ConfigurationResponseTermsFacet;
    return config.missing;
  }

  get isSearchable(): boolean {
    return FacetConfig[this.id]?.search ?? false;
  }
}
