import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import MapConfig from '../../../conf/map';
import {
  SearchRequestTermsFilter,
  SearchResponse,
} from '../../model/api/responses';
import { initVisibleMapRelationsState } from '../../model/map/initVisibleMapRelationsState';
import { Context } from '../../model/search/context';
import { TermsFilter } from '../../model/search/filters/TermsFilter';
import { actions as searchActions } from '../Search/slice'; // import from slice, to prevent circular dependency
import {
  EntityView,
  EntityViews,
  GeneralUIState,
  PolyTooltipPosition,
  SearchView,
  SearchViews,
  StatefulUI,
} from './types';

export const initialState: GeneralUIState = {
  view: {
    entity: EntityViews.factsheet,
    search: SearchViews.list,
  },
  pageTitle: '',
  activeOverlay: null,
  factsheetDevDataON: false,
  factsheetLabelsON: false,
  graphInfopanelON: false,
  hierarchyPolyTooltip: {
    id: null,
    position: null,
  },
  loadingInfopanel: false,
  filtersPanelON: false,
  mapRelations: initVisibleMapRelationsState(),
  timelineDropdownOpen: true,
  timelineRangeErrorON: false,
  timelineAccordionOpen: false,
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const key = 'generalUI';
const generalUISlice = createSlice({
  name: key,
  initialState,
  reducers: {
    show(state, action: PayloadAction<{ name: string }>) {
      state[action.payload.name] = true;
    },
    hide(state, action: PayloadAction<{ name: string }>) {
      state[action.payload.name] = false;
    },
    toggle(state, action: PayloadAction<{ name: string }>) {
      state[action.payload.name] = !state[action.payload.name];
    },
    resetInfopanel(state) {
      state[StatefulUI.graphInfopanelON] = false;
    },
    setView(
      state,
      action: PayloadAction<
        | { page: 'search'; view: SearchView }
        | { page: 'entity'; view: EntityView }
      >,
    ) {
      const { page, view } = action.payload;
      state.view[page as string] = view;
    },
    setPageTitle(state, action: PayloadAction<{ title: string }>) {
      state.pageTitle = action.payload.title;
    },
    setHierarchyTooltip(
      state,
      action: PayloadAction<{
        id: string | null;
        position: PolyTooltipPosition;
      }>,
    ) {
      state.hierarchyPolyTooltip = action.payload;
    },
    toggleMapRelation(state, action: PayloadAction<{ relationCode: string }>) {
      const { relationCode } = action.payload;
      if (!state.mapRelations.hasOwnProperty(relationCode)) return;
      state.mapRelations[relationCode] = !state.mapRelations[relationCode];
    },
    toggleAllMapRelations(state, action: PayloadAction<{ active: boolean }>) {
      Object.keys(state.mapRelations).forEach((relationCode: string) => {
        state.mapRelations[relationCode] = action.payload.active;
      });
    },
    setActiveOverlay(state, action: PayloadAction<{ id: string }>) {
      state.activeOverlay = action.payload.id;
    },
    unsetActiveOverlay(state, action: PayloadAction<{ id: string }>) {
      if (state.activeOverlay === action.payload.id) {
        state.activeOverlay = null;
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(searchActions.searchSuccess, (state, action) => {
        if (action.payload.context !== Context.default) {
          return;
        }
        const initiallyActiveMapRelations = getActivePlaceRelationFilterValues(
          action.payload.jsonResponse,
        );
        Object.keys(state.mapRelations).forEach((relationCode: string) => {
          state.mapRelations[relationCode] =
            initiallyActiveMapRelations.includes(relationCode);
        });
      })
      .addCase(searchActions.searchTerm, (state, action) => {
        if (action.payload.searchView) {
          state.view.search = action.payload.searchView;
        }
      }),
});

export const { name: sliceKey, actions } = generalUISlice;
export default generalUISlice.reducer;

function getActivePlaceRelationFilterValues(response: SearchResponse) {
  if (response.request.filters.hasOwnProperty('relationcodes')) {
    const filter: TermsFilter = TermsFilter.createFromRequestFilter(
      response.request.filters.relationcodes as SearchRequestTermsFilter,
    );
    const activeRelationCodes = filter.terms;
    const activeMapRelationCodes = MapConfig.markers.relations.filter(
      (mapRelationCode: string) =>
        activeRelationCodes.includes(mapRelationCode),
    );
    if (activeMapRelationCodes.length > 0) {
      return activeMapRelationCodes;
    }
  }
  return MapConfig.markers.relations;
}
