import { call, put, select } from 'redux-saga/effects';
import * as api from '../../../api';
import { SearchResponse } from '../../../model/api/responses';
import { Context } from '../../../model/search/context';
import { SearchRequestFactory } from '../../../model/search/factories';
import { SearchRequest } from '../../../model/search/request';
import {
  loadSearchConfigurationIfNotLoaded,
  selectFilterConfigurations,
} from '../../SearchConfiguration';
import { selectRouterSearch } from '../../Router/selectors';
import { actions } from '../slice';
import { handleSearchError } from './handleSearchError';
import { addParamFacetsFalse } from './addParamFacetsFalse';

export function* searchEntityPagination() {
  try {
    yield call(loadSearchConfigurationIfNotLoaded, Context.default);
    const searchQuery: string = yield call(getSearchQuery);
    const jsonResponse: SearchResponse = yield call(
      api.search,
      addParamFacetsFalse(searchQuery),
      Context.default,
    );
    yield put(
      actions.searchSuccess({ jsonResponse, context: Context.pagination }),
    );
  } catch (error: any) {
    yield call(handleSearchError, error, Context.pagination);
  }
}

function* getSearchQuery() {
  const urlQueryStr = yield select(selectRouterSearch);
  const filterConfigurations = yield select(selectFilterConfigurations);
  const searchRequest: SearchRequest =
    SearchRequestFactory.createFromUrlSearchParams(
      urlQueryStr,
      filterConfigurations,
    );
  searchRequest.resolveStartAndRowsForEntityPagination();
  return searchRequest.buildUrlQueryString(false, false);
}
