import { generatePath } from 'react-router-dom';
import {
  EntityView,
  EntityViews,
  SearchView,
  SearchViews,
} from '../features/GeneralUI';
import { RouteUtils } from './RouteUtils';
import { Routes } from './routeConfig';

export interface LinkData {
  langCode: string;
  id: string;
  search?: string;
  view?: EntityView;
}

export interface SearchPageLinkData {
  langCode: string;
  view?: SearchView;
}

export class PermalinkBuilder {
  public static entity({ langCode, id, search = '', view }: LinkData): string {
    let path = generatePath(Routes.ResultView, { id, view: view as any });
    if (path.endsWith(EntityViews.factsheet)) {
      path = path.substring(0, path.indexOf(EntityViews.factsheet) - 1);
    }
    const url = `/${path}${search}`;
    return RouteUtils.localizeRoute(langCode, url);
  }

  public static search({ langCode, view }: SearchPageLinkData): string {
    let path = generatePath(Routes.SearchView, { view: view as any });
    if (path.endsWith(SearchViews.list)) {
      path = path.substring(0, path.indexOf(SearchViews.list) - 1);
    }
    const url = `/${path}`;
    return RouteUtils.localizeRoute(langCode, url);
  }

  public static build(i18n, id, view: EntityView, search = ''): string {
    switch (view) {
      case EntityViews.relations:
        return PermalinkBuilder.relations(i18n, id, search);
      case EntityViews.hierarchy:
        return PermalinkBuilder.hierarchy(i18n, id, search);
      case EntityViews.map:
        return PermalinkBuilder.map(i18n, id, search);
      default:
        return PermalinkBuilder.factsheet(i18n, id, search);
    }
  }

  public static factsheet(i18n, id, search = ''): string {
    return PermalinkBuilder.entity({
      langCode: i18n.language,
      id,
      search,
      view: EntityViews.factsheet,
    });
  }

  public static relations(i18n, id, search = ''): string {
    return PermalinkBuilder.entity({
      langCode: i18n.language,
      id,
      search,
      view: EntityViews.relations,
    });
  }

  public static hierarchy(i18n, id, search = ''): string {
    return PermalinkBuilder.entity({
      langCode: i18n.language,
      id,
      search,
      view: EntityViews.hierarchy,
    });
  }

  public static map(i18n, id, search = ''): string {
    return PermalinkBuilder.entity({
      langCode: i18n.language,
      id,
      search,
      view: EntityViews.map,
    });
  }
}
