import { takeLatest } from 'redux-saga/effects';
import { actions } from '../slice';
import { doDefaultSearch } from './searchDefault';
import { searchEntityHasMap } from './searchEntityHasMap';
import { searchEntityMap } from './searchEntityMap';
import { searchEntityPagination } from './searchEntityPagination';
import { searchInfopanel } from './searchInfopanel';

export const searchSagaKey = 'searchSaga';
export function* searchSaga() {
  yield takeLatest(actions.search.type, doDefaultSearch);
}

export const searchEntityPaginationSagaKey = 'searchEntityPaginationSaga';
export function* searchEntityPaginationSaga() {
  yield takeLatest(actions.searchEntityPagination.type, searchEntityPagination);
}

export const searchInfopanelSagaKey = 'searchInfopanelSaga';
export function* searchInfopanelSaga() {
  yield takeLatest(actions.searchInfopanel.type, searchInfopanel);
}

export const searchEntityMapSagaKey = 'searchEntityMapSaga';
export function* searchEntityMapSaga() {
  yield takeLatest(actions.searchEntityMap.type, searchEntityMap);
}

export const searchEntityHasMapSagaKey = 'searchEntityHasMapSaga';
export function* searchEntityHasMapSaga() {
  yield takeLatest(actions.searchEntityHasMap.type, searchEntityHasMap);
}
